import { Unit } from '@prisma/client';

/**
 * Returns an array of all active product keys from all organizations.
 */
export function getActiveProductKeys(session: any) {
  if (
    !session?.user?.subscription ||
    !Array.isArray(session.user.subscription) ||
    session.user.subscription.length === 0
  )
    return [];
  return session.user.subscription;
}

/**
 * Returns an array of all units from the active product keys.
 * This may contain duplicates if the same unit appears in multiple organizations.
 */
export function getActiveUnits(session: any) {
  const activeProductKeys = getActiveProductKeys(session);
  return activeProductKeys.reduce((units: Unit[], pk: any) => {
    if (pk.units) {
      return units.concat(pk.units);
    }
    return units;
  }, []);
}

/**
 * Returns a deduplicated array of unit objects from the active product keys.
 * This function uses the unit's id to remove duplicates.
 */
export function getUniqueActiveUnits(session: any) {
  const units = getActiveUnits(session);
  const uniqueUnitsMap = new Map();
  units.forEach((unit: Unit) => {
    if (!uniqueUnitsMap.has(unit.id)) {
      uniqueUnitsMap.set(unit.id, unit);
    }
  });
  return Array.from(uniqueUnitsMap.values());
}
