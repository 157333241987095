import dayjs, { ConfigType, Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minmax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
import toArray from 'dayjs/plugin/toArray';
import utc from 'dayjs/plugin/utc';

// Then "extend" dayjs with that plugin:
dayjs.extend(isSameOrAfter);
// Extend dayjs with plugins
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timeZone);
dayjs.extend(toArray);
dayjs.extend(utc);
dayjs.extend(minmax);
dayjs.extend(duration);

// Create a wrapper function that defaults to UTC
const utcDayjs = ((...args: Parameters<typeof dayjs>): Dayjs => {
  return dayjs(...args).utc();
}) as typeof dayjs;

// Copy static properties and methods from dayjs to utcDayjs
Object.assign(utcDayjs, dayjs);

export type { ConfigType, Dayjs };

export default dayjs;
