import dayjs from '@/components/Dayjs';
import {
  AssignmentGroupWithStudents,
  ChallengeSetAssignment,
  CreateSpaceAssignment,
} from 'types/models/Assignment';

import { EngineeringNotebookStepField, FeelingType } from '@prisma/client';
import { FiTarget } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import { HiOutlineCloud, HiOutlineFaceSmile, HiUsers } from 'react-icons/hi2';
import { IoContract } from 'react-icons/io5';
type AssignmentSet = CreateSpaceAssignment | ChallengeSetAssignment;

export const isCreateSpaceAssignment = (
  assignment: AssignmentSet
): assignment is CreateSpaceAssignment => {
  return 'assignment' in assignment;
};

export const isChallengeSetAssignment = (
  assignment: AssignmentSet
): assignment is ChallengeSetAssignment => {
  return 'assignmentSets' in assignment;
};

export const getMappedAssignmentStructure = ({
  assignmentSet,
}: {
  assignmentSet: AssignmentSet;
}):
  | CreateSpaceAssignment['assignment'][0]
  | ChallengeSetAssignment['assignmentSets'] => {
  return isCreateSpaceAssignment(assignmentSet)
    ? assignmentSet.assignment[0]
    : assignmentSet.assignmentSets;
};

const getLatestSubmissionDate = (
  group: AssignmentGroupWithStudents,
  assignmentId: string
): Date | null => {
  // Collect submission dates for this assignment from all students.
  const submissionDates = group?.students
    .flatMap((student) =>
      student.portfolio
        // Filter portfolios for the current assignment that have been submitted.
        .filter(
          (portfolio) =>
            portfolio.assignmentId === assignmentId && portfolio.submitted
        )
        // Map to their submittedAt date.
        .map((portfolio) => portfolio.submittedAt)
    )
    .filter((date): date is Date => !!date); // ensure they are non-null

  if (!submissionDates) {
    return null;
  }
  if (submissionDates?.length === 0) {
    return null;
  }

  // For example, pick the latest submission date.
  // (If your business logic dictates the earliest or some other criteria, adjust accordingly.)
  return submissionDates?.reduce((latest, current) =>
    dayjs(current).isAfter(dayjs(latest)) ? current : latest
  );
};

export const AssignmentSubmissionStatusWithDate = ({
  assignmentSet,
}: {
  assignmentSet: AssignmentSet;
}): {
  submitted: boolean;
  submittedAt: Date | null | undefined;
} => {
  const assignmentData = getMappedAssignmentStructure({
    assignmentSet,
  });
  if (isCreateSpaceAssignment(assignmentSet)) {
    const today = dayjs().utc().startOf('day');

    const createSpaceAssignment =
      assignmentData as CreateSpaceAssignment['assignment'][0];
    // Get the due date normalized to UTC at start of day.
    const dueDate = dayjs(createSpaceAssignment.dueAt).utc().startOf('day');
    // if the due date is in the past, return true
    if (dueDate.isBefore(today)) {
      const computedSubmittedAt =
        createSpaceAssignment.group &&
        createSpaceAssignment.group.students.length > 0
          ? getLatestSubmissionDate(
              createSpaceAssignment.group,
              createSpaceAssignment.id
            )
          : null;

      return {
        submitted: true,
        submittedAt: computedSubmittedAt ?? createSpaceAssignment.dueAt,
      };
    }

    // if the assignment is not active, return true
    if (!createSpaceAssignment.active) {
      return {
        submitted: true,
        submittedAt:
          createSpaceAssignment.submittedAt ?? createSpaceAssignment.dueAt,
      };
    }

    // satisfies the TS compiler to ensure group exists
    const group = createSpaceAssignment.group;
    if (!group) {
      return {
        submitted: false,
        submittedAt: null,
      };
    }
    // on a student view, we only get the current student in the group.
    // on a teacher view, we get all students in the group
    for (const student of group.students) {
      const portfolio = student.portfolio.find(
        (portfolio) => portfolio.assignmentId === createSpaceAssignment.id
      );
      if (!portfolio?.submitted) {
        return {
          submitted: false,
          submittedAt: null,
        };
      }
    }
    return {
      submitted: true,
      submittedAt:
        group.students[group.students.length - 1]?.portfolio?.[0]
          ?.submittedAt ?? createSpaceAssignment.dueAt,
    };
  }
  // if its not create space assignment, then it must be challenge set assignment
  else {
    const challengeSetAssignment =
      assignmentData as ChallengeSetAssignment['assignmentSets'];
    // in challenge set, all assignments have the same due date, so we can just check the first one
    if (challengeSetAssignment?.[0]?.assignment?.[0]?.dueAt < new Date()) {
      return {
        submitted: true,
        submittedAt:
          challengeSetAssignment?.[0]?.assignment?.[0]?.submittedAt ??
          challengeSetAssignment?.[0]?.assignment?.[0]?.dueAt,
      };
    }

    // in student view, we only get the current student's assignments
    // in teacher view, we get all students' assignments

    const allAssignmentsSubmitted = challengeSetAssignment.every(
      (assignmentSet) =>
        assignmentSet.assignment.every((assignment) => assignment.submitted)
    );
    if (allAssignmentsSubmitted) {
      return {
        submitted: true,
        submittedAt:
          challengeSetAssignment?.[challengeSetAssignment.length - 1]
            ?.assignment?.[0]?.submittedAt ??
          challengeSetAssignment?.[0]?.assignment?.[0]?.dueAt,
      };
    } else {
      return {
        submitted: false,
        submittedAt: null,
      };
    }
  }
};

export const extractAssignmentDetails = ({
  assignmentSet,
}: {
  assignmentSet: AssignmentSet;
}) => {
  if (isCreateSpaceAssignment(assignmentSet)) {
    return assignmentSet.assignment[0];
  } else {
    return assignmentSet.assignmentSets[0].assignment[0];
  }
};

export const getFeelingColor = (type: keyof typeof FeelingType): string => {
  switch (type) {
    case 'HAPPY':
    case 'CALM':
    case 'FOCUSED':
    case 'READYTOLEARN':
      return 'bg-green-500';

    case 'SAD':
    case 'SICK':
    case 'WITHDRAWN':
    case 'TIRED':
      return 'bg-blue-300';

    case 'FRUSTRATED':
    case 'WORRIED':
    case 'SILLY':
    case 'EXCITED':
      return 'bg-yellow-500';

    case 'MAD':
    case 'TERRIFIED':
    case 'YELLING':
    case 'FURIOUS':
      return 'bg-red-500';

    default:
      return 'bg-gray-400';
  }
};
export const setNotebookStepIcon = (field?: EngineeringNotebookStepField) => {
  switch (field) {
    case 'OBJECTIVES':
      return <FiTarget size={20} className="text-gray-700" />;
    case 'CONSTRAINTS':
      return <IoContract size={20} className="text-blue-700" />;
    case 'IDEA':
    case 'FEELING':
    case 'CONFUSED':
    case 'QUESTION':
    case 'DISCOVERED':
    case 'CONNECTION':
      return <HiOutlineCloud size={20} className="text-red-700" />;
    default:
      return <GoSearch size={20} className="text-orange-700" />;
  }
};

export const KudosIcon: React.FC<{ isPositive: boolean }> = () => (
  <HiUsers
    size={22}
    className={`rounded-xl p-0.5 text-purple-500 border border-purple-500`}
  />
);

export const FeelingIcon: React.FC<{ type: FeelingType }> = () => {
  return (
    <HiOutlineFaceSmile
      size={22}
      className={`rounded-xl text-green-800 border border-green-800 p-0.5`}
    />
  );
};
