import { showToast } from '@/components/Alert/toast';
import Button from '@/components/Button';
import { getFeelingColor } from '@/utility/assignments';
import {
  getQueryKeyFromEntryType,
  updateLikeData,
  updateLikeDataInMessageThread,
  updateMessageData,
} from '@/utility/reportPages';
import { FeelingType, Like } from '@prisma/client';
import { QueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { createContext, useContext, useState } from 'react';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { TbMessageCircle2, TbMessageCircle2Filled } from 'react-icons/tb';
import {
  CreateSpaceReflectionReport,
  EngineeringNotebookStepsWithLike,
  entryTypeLike,
} from 'types/models/Assignment';
import { useCreateLike } from '../react-query/likes/useCreateLike';
import { useDeleteLike } from '../react-query/likes/useDeleteLike';
import { useCreateMessage } from '../react-query/messages/useCreateMessage';

type Student = {
  firstName: string;
  lastName: string;
  id: string;
  [key: string]: any;
};

interface handleLikeProps {
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  entryType: entryTypeLike;
  messageId?: string;
  messageThreadStudentInfo?: {
    firstName: string;
    lastName: string;
    id: string;
  };
  isImage?: boolean;
  messageThreadOriginalEntry?: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
}
interface LikesAndMessagesReportManagerContextProps {
  ReflectionType: ({
    type,
    sender,
    receiver,
    isPositive,
  }: {
    type: FeelingType;
    sender: Student;
    receiver: Student;
    isPositive: boolean;
  }) => JSX.Element;
  LikeIcon: ({
    entry,
    entryType,
    assignmentType,
  }: {
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport;
    entryType: entryTypeLike;
    assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
  }) => JSX.Element | null;
  MessageIcon: ({
    entry,
    entryType,
    userInfo,
  }: {
    entry:
      | (EngineeringNotebookStepsWithLike & {
          signedImageUrl?: string;
        })
      | CreateSpaceReflectionReport;
    entryType: entryTypeLike;
    userInfo?: Student;
  }) => JSX.Element | null;
  ConversationModalLikeIcon: ({
    entry,
    isImage,
    entryType,
    messageThreadStudentInfo,
    messageThreadOriginalEntry,
  }: {
    isImage: boolean;
    messageThreadOriginalEntry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport;
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string | undefined;
    } & CreateSpaceReflectionReport;
    entryType: entryTypeLike;
    messageThreadStudentInfo: {
      id: string;
      firstName: string;
      lastName: string;
    };
    assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
  }) => JSX.Element;

  openMessagesModal: boolean;
  handleMessagesModalState: () => void;
  messageThreadData:
    | (EngineeringNotebookStepsWithLike & {
        signedImageUrl?: string;
      } & CreateSpaceReflectionReport)
    | undefined;
  setMessageThreadData: (value: any) => void;
  messageUserInfo: Student | undefined;
  messageEntryType: entryTypeLike;
  setMessageEntryType: (value: any) => void;
  handleCreateLike: (entry: handleLikeProps) => void;
  handleDeleteLike: (entry: handleLikeProps) => void;
  handleCreateMessage: (
    { entry, entryType }: handleLikeProps,
    content: string
  ) => void;
  isLoadingCreatingLike: boolean;
  isLoadingDeletingLike: boolean;
  isLoadingCreatingMessage: boolean;
  session: Session | null;
}

export const LikesAndMessagesReportManagerContext =
  createContext<LikesAndMessagesReportManagerContextProps | null>(null);

export const useLikesAndMessagesReportManagerContext = () => {
  const context = useContext(LikesAndMessagesReportManagerContext);

  if (!context) {
    throw new Error(
      'useLikesAndMessagesReportManagerContext must be used within the LikesAndMessagesReportManagerContext Provider'
    );
  }
  return context;
};

export const LikesAndMessagesReportManagerProvider = ({
  children,
  queryClient,
}: {
  children: React.ReactNode;
  queryClient: QueryClient;
}) => {
  const { data } = useSession();
  const currentUserId = data?.user.id ?? '';
  const { mutate: createLike, isPending: isLoadingCreatingLike } =
    useCreateLike();
  const { mutate: deleteLike, isPending: isLoadingDeletingLike } =
    useDeleteLike();
  const { mutate: createMessage, isPending: isLoadingCreatingMessage } =
    useCreateMessage();
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [messageUserInfo, setMessageUserInfo] = useState<Student>();
  const [messageThreadData, setMessageThreadData] = useState<
    | (EngineeringNotebookStepsWithLike & {
        signedImageUrl?: string;
      } & CreateSpaceReflectionReport)
    | undefined
  >(undefined);
  const [messageEntryType, setMessageEntryType] = useState<entryTypeLike>(
    'engineeringNotebookStep'
  );

  const handleLikeError = (error: any) => {
    showToast(
      error.response?.data?.message ||
        error.response?.data?.issues[0].message ||
        error.message,
      'error'
    );
  };

  const handleLikeOperation = async (
    {
      entry,
      entryType,
      messageId,
      isImage,
      messageThreadOriginalEntry,
      assignmentType,
    }: handleLikeProps,
    mutation: 'create' | 'delete'
  ) => {
    try {
      let mutationData;
      let likeMutation: any;
      if (mutation === 'create') {
        mutationData = {
          entryId: entry.id,
          entryType: entryType,
          messageId: messageId,
        };
        likeMutation = createLike;
      } else {
        mutationData = { likeId: entry.like[0].id, messageId };
        likeMutation = deleteLike;
      }

      likeMutation(mutationData, {
        onSuccess: (data: { like: Like[] }) => {
          const operation = mutation === 'create' ? 'add' : 'delete';

          const queryKey = getQueryKeyFromEntryType({
            entry:
              entryType === 'message' && messageThreadOriginalEntry
                ? messageThreadOriginalEntry
                : entry,
            assignmentType: assignmentType,
            userId: currentUserId,
          });

          queryClient.setQueryData(queryKey, (oldData: any) => {
            if (!oldData) return undefined;
            if (entryType === 'message') {
              if (!messageThreadOriginalEntry) return null;
              return updateLikeDataInMessageThread({
                oldData,
                entry,
                entryType,
                like: data.like,
                operation,
                messageThreadOriginalEntry,
                isImage,
                assignmentType: assignmentType,
              });
            } else {
              return updateLikeData({
                oldData,
                entry,
                entryType,
                like: data.like,
                operation,
                assignmentType: assignmentType,
                userId: currentUserId,
              });
            }
          });
        },
        onError: handleLikeError,
      });
    } catch (error) {
      handleLikeError(error);
    }
  };

  const handleCreateLike = (entry: handleLikeProps) =>
    handleLikeOperation(entry, 'create');
  const handleDeleteLike = (entry: handleLikeProps) =>
    handleLikeOperation(entry, 'delete');

  const handleCreateMessage = async (
    { entry, entryType, assignmentType }: handleLikeProps,
    content: string
  ) => {
    try {
      createMessage(
        {
          content,
          entryId: entry.id,
          assignmentId: entry.assignmentId,
          entryType,
        },
        {
          onSuccess: (newData: any) => {
            const queryKey = getQueryKeyFromEntryType({
              entry,
              assignmentType: assignmentType,
              userId: currentUserId,
            });

            queryClient.setQueryData(queryKey, (oldData: any) => {
              if (!oldData) return undefined;
              return updateMessageData({
                oldData,
                entry,
                entryType,
                newMessageThread: newData,
                userId: newData.userId,
                assignmentType: assignmentType,
              });
            });
          },
          onError: handleLikeError,
        }
      );
    } catch (error) {
      handleLikeError(error);
    }
  };

  const LikeIcon = ({
    entry,
    entryType,
    assignmentType,
  }: {
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport;
    entryType: entryTypeLike;
    assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
  }) => {
    const role = data?.user.role;
    const userId = data?.user.id;
    const canLike = role === 'TEACHER';
    const hasLiked = entry?.like?.length > 0;
    const canUnLike = entry?.like?.length && entry?.like?.[0].userId === userId;

    const loading = isLoadingCreatingLike || isLoadingDeletingLike;

    const handleButtonClick = () => {
      if (entry?.like?.length > 0) {
        handleDeleteLike({ entry, entryType, assignmentType });
      } else {
        handleCreateLike({ entry, entryType, assignmentType });
      }
    };

    const debouncedHandleButtonClick = debounce(handleButtonClick, 500);

    return (
      <Button
        className="text-gray-500"
        onClick={debouncedHandleButtonClick}
        size={'icon'}
        color="minimal"
        key={entry?.id}
        disabled={loading || (hasLiked ? !canUnLike : !canLike)}
      >
        {hasLiked ? (
          <AiFillLike size={18} color="orange" />
        ) : (
          <AiOutlineLike size={18} />
        )}
      </Button>
    );
  };

  const MessageIcon = ({
    entry,
    entryType,
    userInfo,
  }: {
    entry:
      | (EngineeringNotebookStepsWithLike & {
          signedImageUrl?: string;
        })
      | CreateSpaceReflectionReport;
    entryType: entryTypeLike;
    userInfo?: Student;
  }) => {
    const role = data?.user.role;
    const hasMessages = entry.messageThread?.[0]?.messages?.length > 0;

    const handleButtonClick = () => {
      setMessageThreadData(entry as EngineeringNotebookStepsWithLike);
      setMessageEntryType(entryType);
      setMessageUserInfo(userInfo);
      setOpenMessagesModal(true);
    };

    if (role === 'TEACHER' || (role === 'STUDENT' && hasMessages)) {
      return (
        <Button onClick={handleButtonClick} size={'icon'} color="minimal">
          {hasMessages ? (
            <TbMessageCircle2Filled size={18} color="gray" />
          ) : (
            <TbMessageCircle2 size={18} />
          )}
        </Button>
      );
    }

    return null;
  };

  const ConversationModalLikeIcon = ({
    isImage,
    entry,
    entryType,

    messageThreadOriginalEntry,
    assignmentType,
  }: {
    isImage: boolean;
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string | undefined;
    } & CreateSpaceReflectionReport;
    messageThreadOriginalEntry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport;
    entryType: entryTypeLike;

    assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
  }) => {
    const handleButtonClick = () => {
      if (entry?.like?.length > 0) {
        handleDeleteLike({
          entry: entry,
          entryType,
          messageId: entry.id,
          messageThreadStudentInfo: messageUserInfo,
          messageThreadOriginalEntry,
          isImage,
          assignmentType,
        });
      } else {
        handleCreateLike({
          entry: entry,
          entryType,
          messageId: entry.id,
          messageThreadStudentInfo: messageUserInfo,
          messageThreadOriginalEntry,
          isImage,
          assignmentType,
        });
      }
    };
    const hasLiked = entry?.like?.length > 0;

    return (
      <Button
        className="text-gray-500"
        onClick={handleButtonClick}
        size={'icon'}
        color="minimal"
        key={entry?.id}
        disabled={isLoadingCreatingMessage}
      >
        {hasLiked ? (
          <AiFillLike size={18} color="orange" />
        ) : (
          <AiOutlineLike size={18} />
        )}
      </Button>
    );
  };

  const handleMessagesModalState = () => {
    setOpenMessagesModal(!openMessagesModal);
  };

  const ReflectionType = ({
    type,
    sender,
    receiver,
    isPositive,
  }: {
    type: FeelingType;
    sender: Student;
    receiver: Student;
    isPositive: boolean;
  }) => {
    if (sender) {
      return (
        <div>
          <span className="font-bold">{receiver.firstName}</span> received{' '}
          {isPositive ? (
            <span className="text-green-500 font-bold">positive</span>
          ) : (
            <span className="text-red-500 font-bold">negative</span>
          )}{' '}
          kudos in {type.toLowerCase()}{' '}
        </div>
      );
    } else {
      return (
        <div className="flex items-center  align-middle">
          <div className="text-sm text-gray-500 font-bold capitalize">
            {type?.toLowerCase()}
          </div>
          <span
            className={`w-3.5 h-3.5 rounded-full ml-1 ${getFeelingColor(type)}`}
          />
        </div>
      );
    }
  };

  return (
    <LikesAndMessagesReportManagerContext.Provider
      value={{
        LikeIcon,
        MessageIcon,
        ConversationModalLikeIcon,
        openMessagesModal,
        handleMessagesModalState,
        messageThreadData,
        setMessageThreadData,
        messageUserInfo,
        messageEntryType,
        setMessageEntryType,
        handleCreateLike,
        handleDeleteLike,
        handleCreateMessage,
        isLoadingCreatingLike,
        isLoadingDeletingLike,
        isLoadingCreatingMessage,
        session: data,
        ReflectionType,
      }}
    >
      {children}
    </LikesAndMessagesReportManagerContext.Provider>
  );
};
