import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import EmptyScreen from '@/components/EmptyScreen';
import { EngineeringNotebookStepField } from '@prisma/client';

import Button from '@/components/Button';
import dayjs from '@/components/Dayjs';
import {
  FeelingIcon,
  getFeelingColor,
  setNotebookStepIcon,
} from '@/utility/assignments';
import React, { createContext, useContext, useMemo } from 'react';
import { BiCollapseVertical, BiExpandVertical } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import {
  EngineeringNotebookStepsWithLikeAndImage,
  ReflectionSection,
} from 'types/models/Assignment';
import { InProgressIcon, NotStartedIcon } from '../Assignments/Progress';
import { useLikesAndMessagesReportManagerContext } from './useLikesAndMessagesReportManager';

type TestingResult = {
  studentId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  entries: Record<string, EngineeringNotebookStepsWithLikeAndImage[]>;
  submitted: boolean;
  submittedAt: Date | null;
  active: boolean;
};

type RenderChallengeSetTestingResultsProps = {
  title: string;
  testingResults: TestingResult[] | undefined;
};

interface ChallengeSetReportManagerContextProps {
  RenderReflections: React.FC<{
    entries: ReflectionSection[];
    title: string;
  }>;
  RenderChallengeSetTestingResults: ({
    title,
    testingResults,
  }: RenderChallengeSetTestingResultsProps) => JSX.Element;
}

export const ChallengeSetReportManagerContext =
  createContext<ChallengeSetReportManagerContextProps | null>(null);
export const useChallengeSetReportManagerContext = () => {
  const context = useContext(ChallengeSetReportManagerContext);

  if (!context) {
    throw new Error(
      'useLikeManagerContext must be used within the LikeManagerContext Provider'
    );
  }
  return context;
};

export const ChallengeSetReportManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { LikeIcon, MessageIcon } = useLikesAndMessagesReportManagerContext();
  const RenderChallengeSetTestingResults = ({
    title,
    testingResults,
  }: RenderChallengeSetTestingResultsProps) => {
    /*******************************************************
     * 1) HOOKS MUST BE UNCONDITIONAL
     *******************************************************/
    const [openItems, setOpenItems] = React.useState<string[]>([]);

    // Precompute all item IDs (students + each iteration) for the open-all/close-all feature
    const allIds = useMemo(() => {
      if (!testingResults) return [];
      const temp: string[] = [];
      testingResults.forEach((testResult, studentIndex) => {
        // Each student-level accordion item
        const studentId = `student-${studentIndex}`;
        temp.push(studentId);

        // Each iteration under that student
        const iterationKeys = Object.keys(testResult.entries); // e.g. ["1", "2", "3", ...]
        iterationKeys.forEach((iteration) => {
          const iterationId = `student-${studentIndex}-iteration-${iteration}`;
          temp.push(iterationId);
        });
      });
      return temp;
    }, [testingResults]);

    // Are we fully open or fully closed?
    const isAllOpen = openItems.length === allIds.length;

    // Handler to toggle everything
    const handleToggleAll = () => {
      setOpenItems(isAllOpen ? [] : allIds);
    };

    /*******************************************************
     * 2) IF NO DATA, RETURN RIGHT AWAY (but after hooks)
     *******************************************************/
    if (!testingResults || testingResults.length === 0) {
      return (
        <div className="pb-6">
          <div className="flex items-end pb-4">
            <h1>{title}</h1>
            <hr className="flex-grow ml-2" />
          </div>
          <div className="flex justify-center py-4 text-gray-500">
            <span>No students have entries in this field.</span>
          </div>
        </div>
      );
    }

    /*******************************************************
     * 3) HELPER TO RENDER A SINGLE ENTRY
     *******************************************************/
    function renderEntry(entry: any, entryIndex: number) {
      return (
        <div
          key={entryIndex}
          className="flex justify-between items-center p-3 border-b"
        >
          <div className="flex gap-3">
            <span className="flex gap-3 min-w-24 text-sm max-h-12">
              {/* The icon from your original function */}
              {setNotebookStepIcon()}
              <p>{entry.signedImageUrl ? 'SCREEN SHOT' : entry.field}</p>
            </span>
            <p className="flex-grow text-ellipsis pl-4">
              {entry.signedImageUrl ? (
                <img
                  alt="Entry"
                  width="300"
                  height="200"
                  src={entry.signedImageUrl}
                />
              ) : (
                entry.data
              )}
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <MessageIcon
              entry={entry}
              entryType={
                entry.signedImageUrl ? 'image' : 'engineeringNotebookStep'
              }
              userInfo={entry.student}
            />
            <LikeIcon
              entry={entry}
              entryType={
                entry.signedImageUrl ? 'image' : 'engineeringNotebookStep'
              }
              assignmentType="CHALLENGES"
            />
            <Timestamp createdAt={entry.createdAt} />
          </div>
        </div>
      );
    }

    /*******************************************************
     * 4) RENDER THE MAIN UI
     *******************************************************/
    return (
      <div className="pb-6">
        {/* Title + Toggle-All Button */}
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow ml-2" />
          <Button
            size="icon"
            color="minimal"
            StartIcon={isAllOpen ? BiCollapseVertical : BiExpandVertical}
            onClick={handleToggleAll}
            tooltip={isAllOpen ? 'Close All' : 'Open All'}
          />
        </div>

        {/* Outer accordion for all students */}
        {/* in Challenges we display all students regardless if they have entries or not - thats the only way for educator to see status check on completion.*/}
        <Accordion
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
          className="w-full text-gray-500 border-b"
        >
          {testingResults.map((testingResult, studentIndex) => {
            const studentAccordionId = `student-${studentIndex}`;

            return (
              <AccordionItem key={studentIndex} value={studentAccordionId}>
                <AccordionTrigger className="flex items-center p-3">
                  <span className="flex w-3/4">
                    {testingResult.firstName} {testingResult.lastName}
                  </span>
                  <div className="flex w-1/4 justify-end">
                    {testingResult.submitted ? (
                      <BsFillCheckCircleFill
                        color="green"
                        size={21}
                        className="mr-1"
                      />
                    ) : testingResult.entries &&
                      Object.entries(testingResult.entries).length > 0 ? (
                      <InProgressIcon width="w-5" height="h-5" />
                    ) : (
                      <NotStartedIcon width="w-5" height="h-5" />
                    )}
                  </div>
                </AccordionTrigger>

                {/* Student-level content includes a nested accordion for each iteration */}
                <AccordionContent className="p-3">
                  <Accordion
                    type="multiple"
                    value={openItems}
                    onValueChange={setOpenItems}
                    className="w-full"
                  >
                    {Object.entries(testingResult.entries).map(
                      ([iteration, entries], iterationIndex) => {
                        // Build a unique ID for each iteration
                        const iterationAccordionId = `student-${studentIndex}-iteration-${iteration}`;
                        // For the border-b logic, replicate your original condition
                        const iterationCount = Object.keys(
                          testingResult.entries
                        ).length;
                        const isNotLast = iterationIndex < iterationCount - 1;
                        const shouldRenderTrigger = entries.some(
                          (entry) => entry.data || entry.signedImageUrl
                        );
                        return (
                          <AccordionItem
                            key={iterationAccordionId}
                            value={iterationAccordionId}
                            className={isNotLast ? 'border-b' : ''}
                          >
                            {shouldRenderTrigger && (
                              <AccordionTrigger className="flex items-center p-3">
                                <span className="font-bold">
                                  Iteration {iteration}
                                </span>
                              </AccordionTrigger>
                            )}
                            <AccordionContent>
                              {entries.length > 0 ? (
                                entries.map((entry, i) => renderEntry(entry, i))
                              ) : (
                                <div className="flex justify-center py-4 text-gray-500">
                                  <span>No entries to display</span>
                                </div>
                              )}
                            </AccordionContent>
                          </AccordionItem>
                        );
                      }
                    )}
                  </Accordion>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    );
  };

  const Timestamp = ({ createdAt }: { createdAt: Date }) => (
    <div className="flex text-sm justify-end text-ellipsis whitespace-nowrap ">
      <span>{dayjs(createdAt).local().format('MM/DD hh:mm a')}</span>
    </div>
  );

  const RenderReflections = ({
    entries,
    title,
  }: {
    entries: ReflectionSection[];
    title: string;
  }) => {
    return (
      <>
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow ml-2" />
        </div>
        {entries && entries.length > 0 ? (
          entries.map((entry: ReflectionSection, idx) => (
            <div
              className="flex flex-row justify-between items-center py-3 border-b text-gray-500 "
              key={idx}
            >
              <div className="flex justify-start gap-3">
                <div className="flex gap-4 items-center">
                  <span className="flex gap-4 min-w-28 font-bold max-h-12  ">
                    <span className="italic">
                      {entry.data
                        ? setNotebookStepIcon(
                            entry.field as EngineeringNotebookStepField
                          )
                        : FeelingIcon({ type: entry.type })}
                    </span>
                    {entry?.student?.firstName}
                  </span>
                </div>
                {entry.data ? (
                  <div className="flex-grow text-ellipsis pl-3  ">
                    <div className="flex items-center  align-middle">
                      <div className="text-sm text-gray-500 font-bold line-clamp-2  ">
                        {entry?.data}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow text-ellipsis pl-3  ">
                    <div className="flex items-center  align-middle">
                      <div className="text-sm text-gray-500 font-bold capitalize">
                        {entry?.type?.toLowerCase()}
                      </div>
                      <span
                        className={`w-3.5 h-3.5 rounded-full ml-1 ${getFeelingColor(
                          entry?.type
                        )}`}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center space-x-1">
                <MessageIcon
                  entryType={
                    entry.page ? 'engineeringNotebookStep' : 'feelingsTracker'
                  }
                  entry={entry}
                  userInfo={entry.student}
                />
                <LikeIcon
                  entry={entry}
                  entryType={
                    entry?.data ? 'engineeringNotebookStep' : 'feelingsTracker'
                  }
                  assignmentType="CHALLENGES"
                />
                <Timestamp createdAt={entry.createdAt} />
              </div>
            </div>
          ))
        ) : (
          <EmptyScreen
            description={'No entries have been submitted yet for this section.'}
            headline={'No Entries'}
          />
        )}
      </>
    );
  };

  return (
    <ChallengeSetReportManagerContext.Provider
      value={{
        RenderReflections,
        RenderChallengeSetTestingResults,
      }}
    >
      {children}
    </ChallengeSetReportManagerContext.Provider>
  );
};
