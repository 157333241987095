import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import Button from '@/components/Button';
import EmptyScreen from '@/components/EmptyScreen';
import { generateUniversalLink } from '@/utility/index';
import { EngineeringNotebookStepField, FeelingType } from '@prisma/client';
import Image from 'next/image';
import React, { createContext, useContext, useState } from 'react';

import dayjs from '@/components/Dayjs';
import {
  FeelingIcon,
  KudosIcon,
  setNotebookStepIcon,
} from '@/utility/assignments';
import { BiCollapseVertical, BiExpandVertical } from 'react-icons/bi';
import {
  CreateSpaceReflectionReport,
  EngineeringNotebookStepsWithLike,
} from 'types/models/Assignment';
import { useLikesAndMessagesReportManagerContext } from './useLikesAndMessagesReportManager';

type Student = {
  firstName: string;
  lastName: string;
  id: string;
  [key: string]: any;
};
interface CreateSpaceReportManagerContextProps {
  renderObjectives: (
    objective: EngineeringNotebookStepsWithLike
  ) => JSX.Element;
  renderConstraints: (
    engineeringNotebookStep: EngineeringNotebookStepsWithLike
  ) => JSX.Element;
  RenderTestingResults: (
    title: string,
    testingResults:
      | Array<{
          entries: (EngineeringNotebookStepsWithLike & {
            signedImageUrl?: string;
          })[];
          id: string;
          firstName: string;
          lastName: string;
        }>[]
      | undefined
  ) => JSX.Element;

  renderKudosAndReflection: (
    entry: CreateSpaceReflectionReport,
    key: string
  ) => JSX.Element;

  ReplaySection: ({
    assignmentId,
    viewReplayInAppText,
    stepByStepText,
  }: {
    assignmentId: string;
    stepByStepText: string;
    viewReplayInAppText: string;
  }) => JSX.Element;

  ReflectionsSection: ({
    kudosAndReflections,
    renderKudosAndReflection,
    reflectionDisplayText,
  }: {
    kudosAndReflections: CreateSpaceReflectionReport[];

    renderKudosAndReflection: (
      entry: CreateSpaceReflectionReport,
      key: string
    ) => JSX.Element;
    reflectionDisplayText: string;
  }) => JSX.Element;
  RenderCriteria: (
    title: string,
    students: Array<{
      id: string;
      firstName: string;
      lastName: string;
      engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
    }>
  ) => JSX.Element;
  RenderConstraint: (
    title: string,
    students: Array<{
      id: string;
      firstName: string;
      lastName: string;
      engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
    }>
  ) => JSX.Element;
}

export const CreateSpaceReportManagerContext =
  createContext<CreateSpaceReportManagerContextProps | null>(null);
export const useCreateSpaceReportManagerContext = () => {
  const context = useContext(CreateSpaceReportManagerContext);

  if (!context) {
    throw new Error(
      'useReportManagerContext must be used within the ReportManagerContext Provider'
    );
  }
  return context;
};

export const CreateSpaceReportManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { LikeIcon, MessageIcon, ReflectionType } =
    useLikesAndMessagesReportManagerContext();

  /**
   * Renders a "Criteria" section with one-click "Open/Collapse All".
   *
   * @param title The title for this section, e.g. "Criteria"
   * @param students Array of students, each having a `criteria` array
   * @param renderObjectives function to render a single objective
   */
  function RenderCriteriaSection(
    title: string,
    students: Array<{
      id: string;
      firstName: string;
      lastName: string;
      // Suppose we store a "criteria" array:
      engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
    }>,
    renderObjectives: (obj: EngineeringNotebookStepsWithLike) => JSX.Element
  ) {
    const [openItems, setOpenItems] = React.useState<string[]>([]);

    if (!Array.isArray(students) || students.length === 0) {
      return (
        <div className="pb-6">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2 my-2" />
          <p className="text-gray-500">No {title} found.</p>
        </div>
      );
    }

    const allIds = students?.map((s) => s.id);
    const isAllOpen = openItems.length === allIds.length;

    function handleToggleAll() {
      setOpenItems(isAllOpen ? [] : allIds);
    }

    if (!students || students.length === 0) {
      return (
        <div className="pb-6">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2 my-2" />
          <p className="text-gray-500">No {title} found.</p>
        </div>
      );
    }
    const isThereAnyEntries = students?.some(
      (student) => student?.engineeringNotebookStep?.length > 0
    );

    return (
      <div className="pb-6">
        <div className="flex items-end mb-2">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2" />
          {isThereAnyEntries && (
            <Button
              size={'icon'}
              color="minimal"
              StartIcon={isAllOpen ? BiCollapseVertical : BiExpandVertical}
              onClick={handleToggleAll}
              tooltip={`${isAllOpen ? 'Collapse All' : 'Open All'}`}
            />
          )}
        </div>

        <Accordion
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
          className="w-full text-gray-500"
        >
          {students.map((student) => (
            <AccordionItem key={student.id} value={student.id}>
              <div
                className={`flex flex-row justify-between items-center gap-2 py-1`}
                key={student.id}
              >
                <AccordionTrigger className="max-h-14" />
                <div className="flex gap-4 items-center">
                  <span className="flex gap-4 min-w-28 text-sm font-bold max-h-12">
                    {setNotebookStepIcon('OBJECTIVES')}
                    {student.firstName}
                  </span>
                  <span className="max-h-12 pr-4">Final</span>
                </div>
                <span className="flex-grow text-ellipsis py-1">
                  {student.engineeringNotebookStep &&
                  student.engineeringNotebookStep.length > 0
                    ? student.engineeringNotebookStep[0].data
                    : 'This student has no entries'}
                </span>
                <div className="flex justify-between">
                  {student.engineeringNotebookStep &&
                    student.engineeringNotebookStep.length > 0 && (
                      <>
                        <MessageIcon
                          entryType="engineeringNotebookStep"
                          entry={student.engineeringNotebookStep[0]}
                          userInfo={student}
                        />

                        <LikeIcon
                          entry={student.engineeringNotebookStep[0]}
                          entryType="engineeringNotebookStep"
                          assignmentType="CREATE_SPACE"
                        />
                      </>
                    )}
                </div>

                <span className="text-sm text-ellipsis whitespace-nowrap">
                  {' '}
                  {student.engineeringNotebookStep &&
                  student.engineeringNotebookStep.length > 0 ? (
                    <Timestamp
                      createdAt={student.engineeringNotebookStep[0].createdAt}
                    />
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <AccordionContent>
                {student.engineeringNotebookStep &&
                student.engineeringNotebookStep.length > 1 ? (
                  <div className=" pl-40 text-sm flex flex-col gap-4">
                    {student.engineeringNotebookStep
                      .slice(1)
                      .map(renderObjectives)}
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-1 text-gray-500">
                    <span>No draft entries available.</span>
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  }

  /**
   * Same approach for Constraints
   */
  function RenderConstraintsSection(
    title: string,
    students: Array<{
      id: string;
      firstName: string;
      lastName: string;
      engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
    }>,
    renderConstraints: (obj: EngineeringNotebookStepsWithLike) => JSX.Element
  ) {
    const [openItems, setOpenItems] = React.useState<string[]>([]);
    if (!Array.isArray(students) || students.length === 0) {
      return (
        <div className="pb-6">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2 my-2" />
          <p className="text-gray-500">No {title} found.</p>
        </div>
      );
    }
    const isThereAnyEntries = students?.some(
      (student) => student?.engineeringNotebookStep?.length > 0
    );

    const allIds = students?.map((s) => s.id);
    const isAllOpen = openItems.length === allIds.length;

    function handleToggleAll() {
      setOpenItems(isAllOpen ? [] : allIds);
    }

    if (!students || students.length === 0) {
      return (
        <div className="pb-6">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2 my-2" />
          <p className="text-gray-500">No {title} found.</p>
        </div>
      );
    }

    return (
      <div className="pb-6">
        <div className="flex items-end mb-2">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2" />
          {isThereAnyEntries && (
            <Button
              size={'icon'}
              color="minimal"
              StartIcon={isAllOpen ? BiCollapseVertical : BiExpandVertical}
              onClick={handleToggleAll}
              tooltip={`${isAllOpen ? 'Collapse All' : 'Open All'}`}
            />
          )}
        </div>

        <Accordion
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
          className="w-full text-gray-500"
        >
          {students.map((student) => (
            <AccordionItem key={student.id} value={student.id}>
              <div
                className={`flex flex-row justify-between items-center gap-2 py-1`}
                key={student.id}
              >
                <AccordionTrigger className="max-h-14" />
                <div className="flex gap-4 items-center">
                  <span className="flex gap-4 min-w-28 text-sm font-bold max-h-12">
                    {setNotebookStepIcon('CONSTRAINTS')}
                    {student.firstName}
                  </span>
                  <span className="max-h-12 pr-4">Final</span>
                </div>
                <span className="flex-grow text-ellipsis py-1">
                  {student.engineeringNotebookStep &&
                  student.engineeringNotebookStep.length > 0
                    ? student.engineeringNotebookStep[0].data
                    : 'This student has no entries'}
                </span>
                <div className="flex justify-between">
                  {student.engineeringNotebookStep &&
                    student.engineeringNotebookStep.length > 0 && (
                      <>
                        <MessageIcon
                          entryType="engineeringNotebookStep"
                          entry={student.engineeringNotebookStep[0]}
                          userInfo={student}
                        />

                        <LikeIcon
                          entry={student.engineeringNotebookStep[0]}
                          entryType="engineeringNotebookStep"
                          assignmentType="CREATE_SPACE"
                        />
                      </>
                    )}
                </div>

                <span className="text-sm text-ellipsis whitespace-nowrap">
                  {' '}
                  {student.engineeringNotebookStep &&
                  student.engineeringNotebookStep.length > 0 ? (
                    <Timestamp
                      createdAt={student.engineeringNotebookStep[0].createdAt}
                    />
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <AccordionContent>
                {student.engineeringNotebookStep &&
                student.engineeringNotebookStep.length > 1 ? (
                  <div className=" pl-40 text-sm flex flex-col gap-4">
                    {student.engineeringNotebookStep
                      .slice(1)
                      .map(renderConstraints)}
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-1 text-gray-500">
                    <span>No draft entries available.</span>
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  }

  const renderObjectives = (criteria: EngineeringNotebookStepsWithLike) => {
    return (
      <div
        className="flex flex-row justify-between items-center py-3"
        key={criteria.id}
      >
        <div className="flex justify-start gap-2">
          <span className="italic">Draft</span>
          <p className="flex-grow text-ellipsis pl-3  ">{criteria.data} </p>
        </div>
        <div className="flex justify-between items-center gap-1">
          <MessageIcon entryType="engineeringNotebookStep" entry={criteria} />
          <LikeIcon
            entry={criteria}
            entryType="engineeringNotebookStep"
            assignmentType="CREATE_SPACE"
          />
          <Timestamp createdAt={criteria.createdAt} />
        </div>
      </div>
    );
  };
  const renderConstraints = (constraint: EngineeringNotebookStepsWithLike) => (
    <div
      className="flex flex-row justify-between items-center py-3"
      key={constraint.id}
    >
      <div className="flex justify-start gap-2">
        <span className="italic">Draft</span>
        <p className="  text-ellipsis pl-3">{constraint.data}</p>
      </div>
      <div className="flex justify-between items-center gap-1">
        <MessageIcon entryType="engineeringNotebookStep" entry={constraint} />
        <LikeIcon
          entry={constraint}
          entryType="engineeringNotebookStep"
          assignmentType="CREATE_SPACE"
        />
        <Timestamp createdAt={constraint.createdAt} />
      </div>
    </div>
  );
  const RenderCriteria = React.useCallback(
    (
      title: string,
      students: Array<{
        id: string;
        firstName: string;
        lastName: string;
        engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
      }>
    ) => {
      return RenderCriteriaSection(title, students, renderObjectives);
    },
    [renderObjectives]
  );

  const RenderConstraint = React.useCallback(
    (
      title: string,
      students: Array<{
        id: string;
        firstName: string;
        lastName: string;
        engineeringNotebookStep: EngineeringNotebookStepsWithLike[];
      }>
    ) => {
      return RenderConstraintsSection(title, students, renderConstraints);
    },
    [renderConstraints]
  );

  const RenderTestingResults = (
    title: string,
    testingResults:
      | Array<{
          entries: (EngineeringNotebookStepsWithLike & {
            signedImageUrl?: string;
          })[];
          id: string;
          firstName: string;
          lastName: string;
        }>[]
      | undefined
  ) => {
    const allIds = React.useMemo(
      () => buildAllIds(testingResults ?? []),
      [testingResults]
    );

    const [openItems, setOpenItems] = useState<string[]>([]);
    // If no data
    if (!testingResults || testingResults.length === 0) {
      return (
        <div className="pb-6">
          <div className="flex items-end pb-4">
            <h1>{title}</h1>
            <hr className="flex-grow border-black border-b-1 ml-2" />
          </div>
          <div className="w-full text-gray-500 text-center">
            No students have entries in this field.
          </div>
        </div>
      );
    }

    // 5) If all IDs are open, "Collapse All" next. Else "Open All."
    const isAllOpen = openItems.length === allIds.length;

    function handleToggleAll() {
      setOpenItems(isAllOpen ? [] : allIds);
    }

    return (
      <div className="pb-6">
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2" />

          {testingResults.length > 0 && (
            <Button
              size={'icon'}
              color="minimal"
              StartIcon={isAllOpen ? BiCollapseVertical : BiExpandVertical}
              onClick={handleToggleAll}
              tooltip={`${isAllOpen ? 'Collapse All' : 'Open All'}`}
            />
          )}
        </div>

        {/* Map each iteration (outer array index) */}
        {testingResults.map((studentsInIteration, i) => (
          <TestIterationAccordion
            key={i}
            iterationIndex={i}
            students={studentsInIteration}
            openItems={openItems}
            setOpenItems={setOpenItems}
          >
            {/* Inside each top-level iteration, map the students */}
            {studentsInIteration.map((student, j) => (
              <StudentAccordion
                key={`${i}-${j}`}
                iterationIndex={i}
                studentIndex={j}
                student={student}
                openItems={openItems}
                setOpenItems={setOpenItems}
              />
            ))}
          </TestIterationAccordion>
        ))}
      </div>
    );
  };

  function buildAllIds(
    testingResults: Array<
      | {
          entries: any[]; // or your specific type
          id: string;
          firstName: string;
          lastName: string;
        }[]
      | undefined
    >
  ): string[] {
    const allIds: string[] = [];

    testingResults?.forEach((iterationArray, i) => {
      // Each top-level item = `iter-${i}`
      const topLevelId = `iter-${i}`;
      allIds.push(topLevelId);

      // Each student item = `student-${i}-${j}`
      iterationArray?.forEach((_, j) => {
        const studentId = `student-${i}-${j}`;
        allIds.push(studentId);
      });
    });

    return allIds;
  }

  function TestIterationAccordion({
    iterationIndex,

    openItems,
    setOpenItems,
    children,
  }: {
    iterationIndex: number;
    students: {
      entries: any[];
      id: string;
      firstName: string;
      lastName: string;
    }[];
    openItems: string[];
    setOpenItems: React.Dispatch<React.SetStateAction<string[]>>;
    children?: React.ReactNode;
  }) {
    const topValue = `iter-${iterationIndex}`;

    return (
      <Accordion
        type="multiple"
        value={openItems}
        onValueChange={setOpenItems}
        className="w-full text-gray-500 mb-2"
      >
        <AccordionItem value={topValue} showBorder>
          <AccordionTrigger className="flex items-center p-1">
            <span className="font-bold py-2">Test {iterationIndex + 1}</span>
          </AccordionTrigger>
          <AccordionContent className="pb-0">{children}</AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }

  function StudentAccordion({
    iterationIndex,
    studentIndex,
    student,
    openItems,
    setOpenItems,
  }: {
    iterationIndex: number;
    studentIndex: number;
    student: {
      entries: any[];
      id: string;
      firstName: string;
      lastName: string;
    };
    openItems: string[];
    setOpenItems: React.Dispatch<React.SetStateAction<string[]>>;
  }) {
    const studentValue = `student-${iterationIndex}-${studentIndex}`;
    if (student.entries.length > 0)
      return (
        <Accordion
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
          className="w-full px-4"
        >
          <AccordionItem value={studentValue} showBorder>
            <AccordionTrigger className="flex items-center">
              <span className="font-bold">{student.firstName}</span>
            </AccordionTrigger>

            <AccordionContent>
              {/* Render each entry */}
              {student.entries.length > 0 ? (
                student.entries.map((entry, entryIndex) => (
                  <div
                    key={entryIndex}
                    className={`flex flex-row justify-between items-center p-3 ${
                      entryIndex < student.entries.length - 1
                        ? 'border-b'
                        : 'border-b'
                    }`}
                  >
                    {/* Left side: icon + text */}
                    <div className="flex justify-start gap-3">
                      <span className="flex gap-3 min-w-24 text-sm max-h-12">
                        {/* Your custom icon logic */}
                        {setNotebookStepIcon()}
                        <p>
                          {entry.signedImageUrl
                            ? 'SCREEN SHOT'
                            : entry.field === 'MODIFY'
                              ? 'IMPROVE'
                              : entry.field}
                        </p>
                      </span>
                      {/* Possibly an image or text */}
                      <p className="flex-grow text-ellipsis pl-4">
                        {entry.signedImageUrl ? (
                          <Image
                            alt="Picture of the Student"
                            width={300}
                            height={200}
                            src={entry.signedImageUrl}
                          />
                        ) : (
                          entry.data
                        )}
                      </p>
                    </div>

                    {/* Right side: your Like, Message, Timestamp, etc. */}
                    <div className="flex justify-end items-center space-x-1">
                      <MessageIcon
                        entryType={
                          entry.signedImageUrl
                            ? 'image'
                            : 'engineeringNotebookStep'
                        }
                        entry={entry}
                        userInfo={{
                          firstName: student.firstName,
                          lastName: student.lastName,
                          id: student.id,
                        }}
                      />
                      <LikeIcon
                        entry={entry}
                        entryType={
                          entry.signedImageUrl
                            ? 'image'
                            : 'engineeringNotebookStep'
                        }
                        assignmentType="CREATE_SPACE"
                      />
                      <Timestamp createdAt={entry.createdAt} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-3 text-gray-500">
                  No entries for {student.firstName}.
                </div>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      );
  }

  // Helper component to display timestamp
  const Timestamp = ({ createdAt }: { createdAt: Date }) => (
    <div className="flex justify-end truncate w-28">
      <span>{dayjs(createdAt).local().format('MM/DD hh:mm a')}</span>
    </div>
  );

  const renderKudosAndReflection = (
    entry: CreateSpaceReflectionReport,
    key: string
  ) => {
    return (
      <div
        className="flex flex-row justify-between items-center py-3 border-b text-gray-500"
        key={key}
      >
        <div className="flex justify-start gap-3">
          <div className="flex  items-center">
            <span className="flex gap-4 min-w-24 font-bold max-h-12  ">
              <span className="italic">
                {' '}
                {entry?.sender
                  ? KudosIcon(entry.isPositive as any)
                  : entry.data
                    ? setNotebookStepIcon(
                        entry.field as EngineeringNotebookStepField
                      )
                    : FeelingIcon(entry.type as any)}
              </span>
              <div className="truncate max-w-20">
                {entry?.sender
                  ? entry.sender.firstName
                  : entry?.student?.firstName}
              </div>
            </span>
          </div>

          <div className="flex-grow text-ellipsis pl-3">
            {entry.data ? (
              <div className="flex items-center align-middle">
                <div className="text-sm text-gray-500 font-bold line-clamp-2">
                  {entry?.data}
                </div>
              </div>
            ) : (
              <ReflectionType
                type={entry.type as FeelingType}
                sender={entry?.sender as Student}
                receiver={entry?.receiver as Student}
                isPositive={entry.isPositive as boolean}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between items-center space-x-1">
          <MessageIcon
            entryType={
              entry?.sender
                ? 'kudo'
                : entry.page
                  ? 'engineeringNotebookStep'
                  : 'feelingsTracker'
            }
            entry={entry}
            userInfo={entry.student ?? entry.sender}
          />

          <LikeIcon
            entry={entry as any}
            entryType={
              entry?.sender
                ? 'kudo'
                : entry.page
                  ? 'engineeringNotebookStep'
                  : 'feelingsTracker'
            }
            assignmentType="CREATE_SPACE"
          />
          <span className="text-sm text-ellipsis whitespace-nowrap">
            <Timestamp createdAt={entry.createdAt} />
          </span>
        </div>
      </div>
    );
  };

  const ReflectionsSection = ({
    kudosAndReflections,

    renderKudosAndReflection,
    reflectionDisplayText,
  }: {
    kudosAndReflections: CreateSpaceReflectionReport[];

    renderKudosAndReflection: (
      entry: CreateSpaceReflectionReport,

      key: string
    ) => JSX.Element;
    reflectionDisplayText: string;
  }) => {
    return (
      <div className="py-4">
        <div className="flex items-end truncate">
          <h1>{reflectionDisplayText}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2" />
        </div>
        <div
          className={`flex flex-col w-full pt-2 pb-4 max-h-128 overflow-auto   
          `}
        >
          {kudosAndReflections && kudosAndReflections.length > 0 ? (
            kudosAndReflections.map((reflectionAndKudo) =>
              renderKudosAndReflection(reflectionAndKudo, reflectionAndKudo.id)
            )
          ) : (
            <EmptyScreen
              headline="No Reflections"
              description="No reflections have been submitted for this assignment."
            />
          )}
        </div>
      </div>
    );
  };

  const ReplaySection = ({
    assignmentId,
    viewReplayInAppText,
    stepByStepText,
  }: {
    assignmentId: string;
    stepByStepText: string;
    viewReplayInAppText: string;
  }) => (
    <div className="py-4">
      <div className="flex items-end">
        <h1>{stepByStepText}</h1>
        <hr className="flex-grow border-black border-b-1 ml-2" />
      </div>
      <div className="flex flex-col gap-2 pb-4">
        <p className="py-1">
          {' '}
          You will be directed back to the app to view the step by step replay.
        </p>
        <Button
          color="outline"
          size={'lg'}
          className=" max-w-52"
          href={`${generateUniversalLink({
            assignmentId: assignmentId,
            replay: true,
          })}`}
        >
          {viewReplayInAppText}
        </Button>
      </div>
    </div>
  );

  return (
    <CreateSpaceReportManagerContext.Provider
      value={{
        renderObjectives,
        renderConstraints,
        RenderTestingResults,
        RenderCriteria,
        RenderConstraint,
        renderKudosAndReflection,
        ReplaySection,

        ReflectionsSection,
      }}
    >
      {children}
    </CreateSpaceReportManagerContext.Provider>
  );
};
