export const APP_NAME = 'Mind Labs';

export const BASE_URL =
  process.env.BASE_URL || `https://${process.env.VERCEL_URL}`;

export const isENVDev = process.env.NODE_ENV === 'development';
export const VERCEL_ENV = process.env.VERCEL_ENV;
export const WEBSITE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'https://www.learnwithexplore.com';

export const NEXT_PUBLIC_BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || `https://${process.env.VERCEL_URL}`;

export const WEBAPP_URL = WEBSITE_URL || 'http://localhost:3000';
export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_ENV_STATUS === 'production';

export const Routes = {
  SITE: {
    HOME: '/',
    DASHBOARD: '/dashboard',
    ADMIN_DASHBOARD: '/admin/dashboard',
    ADMIN_LIST_USERS: '/admin/users',
    ADMIN_PROFILE: '/profile/:adminId',
    TEACHER_PROFILE: '/profile/:teacherId',
    STUDENT_PROFILE: '/profile/:studentId',
    STUDENT_ASSIGNMENTS: '/assignments',
    STUDENT_ASSIGNMENT: '/assignment/:assignmentId',
    TEACHER_ROSTER: '/roster',
    TEACHER_CLASSROOMS: '/classrooms',
    TEACHER_CLASSROOM: '/classroom/:classroomId',
    TEACHER_CLASSROOM_GROUP: '/classroom/:classroomId/groups',
    TEACHER_CLASSROOM_ACTIVE: '/classroom/:classroomId/active',

    TEACHER_TASKS: '/tasks',
    REGISTER: '/auth/signup',
    LOGIN: '/auth/signin',
    RPOFILE: '/settings/my-account/profile',
    USERS: '/users',
    _500: '/500',
    VERIFY_ACCOUNT: '/auth/verify-account',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    CHANGE_PASSWORD: '/auth/change-password',
    LOGOUT: '/auth/logout',
    NOT_FOUND: '/404',
    LAUNCH_GAME: '/game/p?token=:token',
  },
  API: {
    I18N: '/api/i18n',
    USERS: '/api/users',
    STUDENTS: '/api/students',
    PROFILE: '/api/users/:id',
    SESSION: '/api/auth/session',
    CLASSROOMS: '/api/classrooms',
    CLASSROOM_ACCESS_CODE: '/api/classrooms/access-code',
    ASSIGNMENTS: '/api/assignments',
    ASSIGNMENT_SUPER_SET_DATA:
      '/api/assignments/reports/challenge-set/collection',
    TASKS: '/api/tasks',
    DASHBOARD: '/api/dashboard',
    RESET_PASSWORD: '/api/auth/reset-password',
    FORGOT_PASSWORD: '/api/auth/forgot-password',
    VERIFY_ACCOUNT: '/api/auth/verify-account',
    GROUPSETS: '/api/classrooms/:classroomId/groupsets',
    GROUPSET: '/api/classrooms/:classroomId/groupsets/:groupsetId',
    GROUP: '/api/classrooms/:classroomId/groups/:groupId',
    CLASSROOM_MEMBERS: '/api/classrooms/:classroomId/members',
    LIKES: '/api/likes',
    MESSAGES: '/api/assignments/:assignmentId/messages',
    TEXT_LABELS: '/api/text-labels',
    SUBSCRIPTIONS: '/api/subscriptions',
    ALL_PRODUCT_KEYS: '/api/subscriptions/all-product-keys',
    ANALYTICS: '/api/analytics/data',
    ANALYTICS_ASSIGNMENTS: '/api/analytics/assignments',
    ANALYTICS_CLASSROOMS: '/api/analytics/classrooms',
    ANALYTICS_STUDENTS: '/api/analytics/students',
    ANALYTICS_GROUPS: '/api/analytics/groups',
  },
} as const;

// ----------- redirects getServerSideProps

export const Redirects = {
  NOT_FOUND: {
    redirect: {
      destination: Routes.SITE.NOT_FOUND,
      permanent: false,
    },
  },
  _500: {
    redirect: {
      permanent: false,
      destination: Routes.SITE._500,
    },
  },
  LOGIN: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.LOGIN,
    },
  },
  HOME: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.HOME,
    },
  },
  DASHBOARD: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.DASHBOARD,
    },
  },
  ASSIGNMENTS: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.STUDENT_ASSIGNMENTS,
    },
  },
  CLASSROOMS: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.TEACHER_CLASSROOMS,
    },
  },
  TEACHER_CLASSROOM_ACTIVE_ASSIGNMENTS: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.TEACHER_CLASSROOM_ACTIVE,
    },
  },
  PROFILE: {
    redirect: {
      permanent: false,
      destination: Routes.SITE.RPOFILE,
    },
  },
} as const;

// grades for dropdown
export const GRADES = [
  { value: 'K', label: 'K' },
  { value: 'FIRST', label: '1st' },
  { value: 'SECOND', label: '2nd' },
  { value: 'THIRD', label: '3rd' },
  { value: 'FOURTH', label: '4th' },
  { value: 'FIFTH', label: '5th' },
  { value: 'SIXTH', label: '6th' },
  { value: 'SEVENTH', label: '7th' },
  { value: 'EIGHTH', label: '8th' },
  // { value: 'MULTIPLE', label: 'MULTIPLE' },
];

// Units for dropdown
export const UNITS = [
  { value: 'forces-and-motion', label: 'Forces and Motion' },
  { value: 'energy-and-circuits', label: 'Energy and Circuits' },
  // { value: 'light-and-sound', label: 'Light and Sound' },
];

// tasks types for select drop-down in Tasks page filter toolbar
export const TASKS_TYPES = [
  {
    value: 'CHALLENGE_SET',
    label: 'Challenge Set',
  },
  {
    value: 'CREATE_SPACE',
    label: 'Create Space',
  },
];

// temp create space units
export const tempUpdatedCreateSpaceUnits = [
  ...UNITS,
  { value: 'light-and-sound', label: 'Light and Sound' },
  { value: 'colabs-plus', label: 'Colabs Plus' },
  { value: 'habitat-and-adapt', label: 'Habitat and Adapt' },
];

export const ASSIGNMENT_TYPES = [
  { value: 'CREATE_SPACE', label: 'Create Space' },
  { value: 'CHALLENGE_SET', label: 'Challenge Set' },
];
